<template>
  <div class="container">
    <div>
      <!-- 用户区域 -->
      <div class="user_footer">
        <van-popover
          v-model="showPopover"
          trigger="click"
          :actions="actions"
          @select="onSelect"
          placement="bottom-end"
          class="icon_cs"
        >
          <template #reference>
            <van-icon  name="setting-o"/>
          </template>
        </van-popover>
          
          <div class="hreadImg"></div>
          <div style="text-align: center; margin-top: 30px; color: white;">{{username[0]}}</div>
          <div style="text-align: center; margin-top: 15px; color: white; font-size: 14px; padding-bottom: 40px;">
              {{username[1]}} <span style="display: inline-block; color: #e69329; font-size: 16px; padding: 0 5px;">|</span> {{accountNum}}
          </div>
          <div style="text-align: center; margin-top: 15px; color: white; font-size: 14px;">
            
          </div>
      </div>
      <!-- 菜单区域 -->
      <div class="menu_footer">
          <van-cell title="联系人管理" style=" margin: 20px 0;" @click="routAdmin" icon="friends-o" is-link/>
          <van-cell title="商家信息管理" style="margin: 20px 0;" icon="hotel-o" @click="routMerchant" is-link/>
          <van-cell title="收款账户管理" style="margin: 20px 0;" @click="routCollectionInfo" icon="after-sale" is-link/>
          <van-cell title="关于系统" style="margin: 20px 0;" @click="getSystemState" icon="info-o" is-link/>
      </div>
    </div>

    <van-tabbar route>
      <van-tabbar-item to="/productAudit" icon="wap-nav">产品提报库</van-tabbar-item>
      <van-tabbar-item to="/brand" icon="card">品牌库</van-tabbar-item>
      <van-tabbar-item to="/product" icon="gift-card">产品库</van-tabbar-item>
      <van-tabbar-item to="/mine" icon="manager">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { Dialog } from 'vant'
export default {
  name: 'Mine',
  data() {
    return {
      showPopover: false,
      actions: [{ text: '修改密码', icon: 'edit'}, { text: '退出登录', icon: 'close'}],
    }
  },
  computed: {
    username() {
      let username = this.$cookies.get('ms_username')
      if (username != null || username != undefined) {
        username = username.split('-')
      }
      return username != null ? username : this.name
    },
    accountNum() {
      let accountNum = this.$cookies.get('ms_phone')
      return accountNum ? accountNum : this.accountNum
    }
  },
  methods: {
    onSelect(action){
        if(action.text == '修改密码'){
          this.$router.push({
            path: "/setPass",
            query: {
            },
          });
        }else if(action.text == '退出登录'){
            this.setExit();
        }
    },
    getSystemState(){
      window.open('https://mp.weixin.qq.com/mp/appmsgalbum?__biz=MzkxNjI4NDU3MA==&action=getalbum&album_id=2451526661650235392#wechat_redirect');
    },
    // 退出登录
    setExit(){
        Dialog.confirm({
          title: '退出登录',
          message: '您确定要退出当前账号吗?',
        })
        .then(() => {
          this.$http
            .ajax(
              '/ffkj-main/merchant/unbind',
              {
                openId: window.localStorage.openId
              },
              'get',
              {}
            )
            .then(res => {
              if (res.code == 0) {
                window.localStorage.clear();
                this.$router.push('/login')
              } else {
                Notify({ type: 'danger', message: '退出登录失败!!!' })
              }
            })
        })
        .catch(() => {
        });
    },
    closeClick() {
      this.show = false
    },
    // 联系人管理
    routAdmin(){
        this.$router.push({
          path: "/linkmanPerson",
        });
    },
    // 商家信息
    routMerchant(){
        this.$router.push({
          path: "/merchantInfo",
          query: {
          },
        });
    },
    routCollectionInfo(){
        this.$router.push({
          path: "/collectionInfo",
          query: {
          },
        });
    },
    saveEditpwdForm() {
      if (!posswordReg.test(this.editpwdForm.oldpwd)) {
        Notify({ type: 'danger', message: '旧密码输入格式错误' })
        return false
      }
      if (!posswordReg.test(this.editpwdForm.newpwd)) {
        Notify({ type: 'danger', message: '新密码输入格式错误' })
        return false
      }
      if (this.editpwdForm.oldpwd == this.editpwdForm.newpwd) {
        Notify({ type: 'danger', message: '新密码和旧密码不能相同' })
        return false
      }
      if (this.editpwdForm.newpwd != this.editpwdForm.newpwd2) {
        Notify({ type: 'danger', message: '两次密码输入不一致' })
        return false
      }
      this.$http
        .ajax(
          '/ffkj-main/merchant/updatePassword',
          {
            oldPassword: this.$md5(this.editpwdForm.oldpwd),
            newPassword: this.$md5(this.editpwdForm.newpwd2),
            phone: window.localStorage.getItem('ms_phone')
          },
          'post',
          {}
        )
        .then(res => {
          if (res.code == 0) {
            Notify({ type: 'success', message: '密码修改成功' })
            // 成功
            setTimeout(
              function () {
                this.toLogin()
              }.bind(this),
              500
            )
          } else {
            Notify({ type: 'danger', message: res.message })
          }
        })
    },
    // 退出去登录
    toLogin() {
      window.localStorage.clear()
      this.$store.commit('saveMerchantId', '')
      this.$store.commit('saveOperator', '')
      this.$store.commit('saveBranchId', '')
      this.$store.commit('saveServiceType', '')
      this.$store.commit('saveIsEfs', 0)
      this.$store.commit('saveResources', '')
      this.$cookies.remove('resources')
      this.$cookies.remove('ms_username')
      this.$cookies.remove('ms_phone')
      this.$cookies.remove('serviceType')
      this.$cookies.remove('isEfs')
      this.$router.push('/')
    },
  }
}
</script>
<style>
    .van-popover[data-popper-placement=bottom-end] .van-popover__arrow{
      right: 4px !important;
    }
</style>
<style scoped>
</style>